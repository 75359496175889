import memoize from 'lodash-es/memoize'
import type { FieldOptionColor } from '#field/constant'
import { DEFAULT_FIELD_OPTION_COLOR, FieldOptionTextColor } from '#field/constant'

export const getFieldOptionPreset = memoize((optionColor?: FieldOptionColor) => {
  const background = optionColor || DEFAULT_FIELD_OPTION_COLOR
  const color = FieldOptionTextColor[background] || FieldOptionTextColor[DEFAULT_FIELD_OPTION_COLOR]

  return {
    background,
    color
  }
}, (optionColor) => optionColor)
